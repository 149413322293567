<template>
  <div class="comment">
    <div class="userInfo" v-if="commentInfo">
      <!-- <span class="picture"><img :src="getUserImage(commentInfo.createdById)"/></span> -->
      <div class="userNameEmail">
        <span class="name"> {{ getUserName(commentInfo.createdById) }} </span>
        <span class="email"> {{ getUserEmail(commentInfo.createdById) }}</span>
      </div>

      <span class="commentDate">
        <span class="material-icons"> av_timer </span>
        {{ getReadableCreatedAtDate(commentInfo.createdAt) }}
      </span>
    </div>
    <div class="comment-message">
      <p>{{ commentInfo.message }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    commentInfo: {
      type: Object
    }
  },
  computed: {
    ...mapGetters('tasks', ['users'])
  },
  methods: {
    getUserName(userId) {
      const user = this.getUser(userId);
      return user ? `${user.firstName} ${user.lastName}` : '';
    },

    getUserEmail(userId) {
      return this.getUser(userId) ? this.getUser(userId).email : '';
    },

    getUserImage(userId) {
      return this.getUser(userId)
        ? this.getUser(userId).imageUrl === '' || this.getUser(userId).imageUrl == undefined
          ? this.userImagePath()
          : this.getUser(userId).imageUrl
        : this.userImagePath();
    },

    userImagePath() {
      if (window.location.hostname === 'localhost') {
        return '/img/empty-image@1x.png';
      } else {
        return '/img/empty-image@1x.png';
      }
    },

    getUser(userId) {
      if (this.users.length > 0) {
        return this.users.find((user) => Number(user.id) === Number(userId));
      }
      return {
        name: '',
        firstName: '',
        lastName: '',
        email: '',
        id: 0,
        imageUrl: '/img/Empty-image@1x.png',
        filterItems: []
      };
    },

    getReadableCreatedAtDate(dateString) {
      const date = new Date(dateString);
      const monthName = [
        'month.january',
        'month.february',
        'month.march',
        'month.april',
        'month.may',
        'month.june',
        'month.july',
        'month.august',
        'month.september',
        'month.octobre',
        'month.november',
        'month.december'
      ];

      const currentMonthName = this.$t(monthName[date.getMonth()]);
      return `${('0' + date.getDate()).slice(-2)} ${currentMonthName} ${date.getFullYear()}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.comment {
  margin-bottom: 32px;
}

.userNameEmail {
  position: relative;
  display: flex;
  flex-direction: column;
}

.userInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

@media only screen and (max-width: 1000px) {
  .tags {
    margin: 0px;
  }

  .userNameEmail {
    display: flex;
    flex-direction: column;
  }

  .userInfo {
    .commentDate {
      &.overdue {
        color: #e32;
      }
    }
  }
}

.picture {
  width: 32px;
  height: 32px;
  border-radius: 3px;
  display: inline-block;
  margin-right: 16px;

  img {
    width: 32px;
    border-radius: 4px;
    max-height: 32px;
  }
}

.name {
  font-weight: bold;
}

.name,
.email {
  font-size: var(--text-size-small);
}

.commentDate {
  font-size: var(--text-size-small);
  color: #818e96;

  &.overdue {
    color: #e32;
  }

  .material-icons {
    vertical-align: bottom;
  }
}
</style>
