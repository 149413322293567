<template>
  <div class="container mt-4">
    <div :id="task.id" class="task" :class="{ disabled: !interactionAllowed }">
      <div class="top">
        <template v-if="task.dueDate" class="top-checkbox">
          <div class="task-tile">
            <el-checkbox
              :name="title"
              :key="task.taskId"
              @change="taskStatusChange($event, task)"
              class="check-box-element"
              :value="task.isFinished"
              :disabled="!interactionAllowed"
              tabindex="0"
            ></el-checkbox>
            <div>
              <div class="title">
                <el-tooltip effect="light" :content="title" placement="top" v-if="title.length > 25">
                  <span class="title">{{ title }}</span>
                </el-tooltip>
                <span v-else class="title">{{ title }}</span>
              </div>
              <span
                role="button"
                tabindex="0"
                v-if="description.length"
                class="show-desc"
                @click="
                  showDescription = !showDescription;
                  addTargetBlankToTaskLinks();
                "
                :aria-pressed="String(showDescription)"
                >{{ !showDescription ? $t('task.show.more') : $t('task.show.less') }}</span
              >
            </div>
            <span v-if="task.dueDate" :class="{ outdated: isOutdated(task.dueDate), timeLeft: true }">
              <span class="material-icons"> av_timer </span>{{ getReadableDueData(task.dueDate) }}</span
            >
            <div class="collapses">
              <collapse
                v-if="task.type === 10"
                @changeSectionOpen="
                  commentSectionOpen = $event;
                  attachedFilesSectionOpen = false;
                "
                :amountOfComments="task.commentsCount"
                :materialIcon="'comment'"
              ></collapse>
              <collapse
                v-if="task.type === 10"
                @changeSectionOpen="
                  attachedFilesSectionOpen = $event;
                  commentSectionOpen = false;
                "
                :showBadge="false"
                :materialIcon="'description'"
              ></collapse>
            </div>
          </div>
        </template>
        <template v-else>
          <router-link :to="task.route">
            <div class="legacy-task">
              <span class="material-icons"> link </span>
              <span class="el-checkbox__label">{{ title }}</span>
            </div>
          </router-link>
        </template>
      </div>
      <div class="description" v-if="showDescription && description" v-html="description"></div>
      <div class="bottom" :class="{ seperate: commentSectionOpen || attachedFilesSectionOpen }">
        <comment-section
          v-if="commentSectionOpen && task.type === 10"
          :taskAssignmentId="task.id"
          :totalComments="task.commentsCount || task.commentCount"
          @extraComment="task.commentsCount++"
        ></comment-section>
        <attached-files-section
          v-if="attachedFilesSectionOpen && task.type === 10"
          :taskDefinitionId="task.taskDefinitionId"
        ></attached-files-section>
      </div>
    </div>
  </div>
</template>

<script>
import Collapse from './Collapse.vue';
import CommentSection from './comment/CommentSection.vue';
import AttachedFilesSection from './attachedFiles/AttachedFilesSection.vue';
import planhatMixin from '../../mixins/planhat';
import { attachRedirectLinks } from '@/utils/attachRedirectLinks';

export default {
  components: {
    Collapse,
    CommentSection,
    AttachedFilesSection
  },
  mixins: [planhatMixin],
  props: {
    task: {
      type: Object
    },
    interactionAllowed: {
      type: Boolean
    }
  },
  data() {
    return {
      allowCancel: false,
      loadingChangeStatus: true,
      commentSectionOpen: false,
      attachedFilesSectionOpen: false,
      showDescription: false
    };
  },
  computed: {
    title() {
      return this.task.title;
    },
    description() {
      return this.task.description;
    }
  },
  mounted() {
    if (this.$route.hash) {
      const id = this.$route.hash.replace('#', '');
      const el = document.getElementById(id);
      if (el) {
        setTimeout(() => {
          window.scrollTo(0, el.getBoundingClientRect().top);
        }, 10);
      }
    }
  },
  methods: {
    addTargetBlankToTaskLinks() {
      setTimeout(() => {
        attachRedirectLinks(this.$el);
      }, 100);
    },
    isOutdated(dateString) {
      return new Date(dateString).valueOf() < new Date().valueOf();
    },
    getReadableDueData(dateString) {
      const date = new Date(dateString);
      const monthName = [
        'month.january',
        'month.february',
        'month.march',
        'month.april',
        'month.may',
        'month.june',
        'month.july',
        'month.august',
        'month.september',
        'month.octobre',
        'month.november',
        'month.december'
      ];

      const currentMonthName = this.$t(monthName[date.getMonth()]);
      return `${date.getDate()} ${currentMonthName} ${date.getFullYear()}`;
    },
    taskStatusChange(isFinished, task) {
      if (isFinished) {
        this.setTaskFinished(task);
        this.$store
          .dispatch('tasks/completeTask', task.id)
          .then(() => this.track('tal_task_completed'))
          .catch(() => this.setTaskUnfinished(task.id));
      } else {
        this.setTaskUnfinished(task);
        this.$store.dispatch('tasks/uncompleteTask', task.id).catch(() => this.setTaskFinished(task.id));
      }
    },
    setTaskFinished(task) {
      this.$store.commit('tasks/setTaskStatus', { taskAssignmentId: task.id, status: 1 });
    },
    setTaskUnfinished(task) {
      this.$store.commit('tasks/setTaskStatus', { taskAssignmentId: task.id, status: 0 });
    }
  }
};
</script>

<style lang="scss">
.timeLeft {
  color: #021c2c;
  text-align: right;

  .material-icons {
    vertical-align: bottom;
    font-size: var(--subtitle-size);
    margin-right: 4px;
  }
}
.timeLeft.outdated {
  color: #d0021b;
}

body.visma {
  .title {
    font-family: 'Ubuntu', 'Open Sans', 'Helvetica Neue', 'Open Sans', 'Segoe UI', Roboto, Oxygen, sans-serif;
  }
}

.task {
  .collapses {
    justify-self: end;
  }

  .task-tile {
    display: grid;
    grid-template-columns: 32px 2.5fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 10px;
    align-items: center;
  }

  @media (max-width: 576px) {
    .task-tile {
      grid-template-columns: 32px 4fr;
      grid-template-rows: 2fr;
    }
    .timeLeft {
      grid-column: 2/4;
      grid-row: 2/4;
      text-align: left;
    }
    .collapses {
      grid-column: 1/4;
      grid-row: 2/4;
    }
  }

  @media (min-width: 1024px) {
    .task-tile {
      grid-template-columns: 32px 3fr 1fr 0.5fr;
    }
  }

  .title {
    color: #021c2c;
    font-family: Telegraf;
    font-weight: 500;
  }
  .el-checkbox__original {
    display: none;
  }
  .el-checkbox__input:hover,
  .el-checkbox__input:focus-visible {
    .el-checkbox__inner {
      border: solid 1px black !important;
      transition: none;
    }
    .el-checkbox__inner:before {
      display: block;
      position: absolute;
      content: '';
      border-left: 0;
      border-top: 0;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      left: 13px !important;
      top: 7px !important;
      height: 13px !important;
      width: 3px !important;
      transform: rotate(45deg) scaleY(1);
      box-sizing: content-box;
      background-color: transparent;
      transition: display 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
        background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
    }
  }
}

#page-content-wrapper {
  background-color: white !important;
}
</style>

<style lang="scss" scoped>
.show-desc {
  padding-left: 0;
  opacity: 0.8;
  line-height: 18px;
  font-size: var(--text-size-small);
  color: #021c2c;
  cursor: pointer;
}
.task {
  border: 1px solid #d9dde0;
  border-radius: 4px;
  position: relative;

  a:hover,
  a {
    color: #021c2c;
  }

  .legacy-task {
    position: relative;
    display: flex;

    .el-checkbox__label {
      padding-left: 45px;
      white-space: normal;
      width: 100%;
    }
    .material-icons {
      position: absolute;
      top: -7px;
    }
  }

  .material-icons {
    font-size: 32px;
  }

  &.disabled {
    opacity: 0.5;
  }

  .description {
    padding-left: 57px;
    opacity: 0.8;
    max-width: 92%;
  }

  .action {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  .tags {
    margin-left: 48px;
    margin-top: 16px;
  }

  .top {
    position: relative;
    padding: 16px;
  }

  .bottom.seperate {
    border-top: 1px solid #d9dde0;
  }

  margin-bottom: 16px;
}
</style>
